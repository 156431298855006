.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.collapse-container {
  width: 100%;
}

.collapse-content {
  width: 100%;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

/*Small badges - mostly in tables*/
.dtr-control.sorting_1 {
  padding: 5px;
}

.react-datepicker {
  display: flex;
  border-radius: 10px;
}

.react-datepicker__triangle {
  left: -15px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container input {
  width: 100%;
}

.collapse-content.open {
  max-height: 650px;
  opacity: 1;
}

.card {
  border-radius: 10px;
}

.card.form {
  z-index: 50;
}

.card.super-form {
  z-index: 60;
}

.input {
  z-index: 60;
}

.max-w-80 {
  max-width: 80%;
}

.card-hovered:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.border-info-dark {
  position: relative;
  border-radius: 10px;
  padding-bottom: 5px;
  z-index: 0;
  background-color: white;
}

.border-info-dark::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  padding: 2px;
  background: linear-gradient(to right, #1797be, #7266ba);
  mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
  z-index: -1;
}

.btn {
  border-radius: 5px;
}

.popover {
  border-radius: 10px !important;
  border: 0;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.rounded-bottom {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.rounded-element {
  border-radius: 7px !important;
}

.rounded-left {
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.text-gradient-yellow-green {
  background: -webkit-linear-gradient(left, #fad732, #37bc9b);
  background: -o-linear-gradient(right, #fad732, #37bc9b);
  background: -moz-linear-gradient(right, #fad732, #37bc9b);
  background: linear-gradient(to right, #fad732, #37bc9b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-blue-orange {
  background: -webkit-linear-gradient(left, rgb(23, 165, 190), #e16d00);
  background: -o-linear-gradient(right, rgb(23, 165, 190), #e16d00);
  background: -moz-linear-gradient(right, rgb(23, 165, 190), #e16d00);
  background: linear-gradient(to right, rgb(23, 165, 190), #e16d00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-orange-blue {
  background: -webkit-linear-gradient(left, #e16d00, rgb(23, 165, 190));
  background: -o-linear-gradient(right, #e16d00, rgb(23, 165, 190));
  background: -moz-linear-gradient(right, #e16d00, rgb(23, 165, 190));
  background: linear-gradient(to right, #e16d00, rgb(23, 165, 190));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-blue-green {
  background: -webkit-linear-gradient(left, rgb(23, 165, 190), #37bc9b);
  background: -o-linear-gradient(right, rgb(23, 165, 190), #37bc9b);
  background: -moz-linear-gradient(right, rgb(23, 165, 190), #37bc9b);
  background: linear-gradient(to right, rgb(23, 165, 190), #37bc9b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-blue-purple {
  background: -webkit-linear-gradient(left, #1797be, #7266ba);
  background: -o-linear-gradient(right, #1797be, #7266ba);
  background: -moz-linear-gradient(right, #1797be, #7266ba);
  background: linear-gradient(to right, #1797be, #7266ba);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.whirl::after {
  background-color: rgba(245, 245, 245, 0.39) !important;
  z-index: 100 !important;
}

.whirl.small-spinner::after {
  height: 20px;
  width: 20px;
  top: 100%;
  left: 65%;
}

.area-disabled {
  pointer-events: none;
  opacity: 0.7;
  border-radius: 10px;
}

button:disabled {
  /*cursor: not-allowed;*/
  opacity: 0.6;
}

.bg-info-tax-bands {
  background-color: rgba(63, 85, 220, 0.54) !important;
  color: #fff;
}

.bg-info-tax-bands-warning {
  background-color: rgba(231, 128, 0, 0.34) !important;
}
